import React from 'react';
import './UserInfo.css';
import avatar_nan from '../../utils/images/avatar.svg';
import useUserStore from '../../utils/stores/useUserStore';

const UserInfo = ({ fetchedProfile }) => {
    const { userProfile } = useUserStore();
    const hasRole = (roleName) => userProfile.roles.some(role => role.name === roleName);
    const profile = fetchedProfile || userProfile;
    if (!profile) {
        return null;
    }

    return (
        <div className='user_info__block'>
            <img className='user_info__avatar' src={avatar_nan} alt="User Avatar" />
            <div className='user_info__text-block'>
                <h2 className='user_info__title' title={profile.full_name ? `${profile.full_name}` : 'Не удалось получить данные'}>
                    {profile.full_name ? `${profile.full_name}` : 'Не удалось получить данные'}
                </h2>
                <p className='user_info__subtitle' title={profile.department ? profile.department : 'Не удалось получить данные'}>
                    {profile.department ? profile.department : 'Не удалось получить данные'}
                </p>
                <p className='user_info__subtitle' title={profile.position ? profile.position : 'Не удалось получить данные'}>
                    {profile.position ? profile.position : 'Не удалось получить данные'}
                </p>
            </div>
        </div>
    );
}

export default UserInfo;
