import React, { useEffect, useRef } from 'react';
import './SuccessQuizPopup.css';
import success from '../../../utils/images/success.svg';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

function SuccessQuizPopup({ isSuccess, setSuccess }) {
    const navigate = useNavigate();
    const popupRef = useRef();

    useEffect(() => {
        if (isSuccess) {
            document.body.classList.add('body-scroll-lock');
        } else {
            document.body.classList.remove('body-scroll-lock');
        }
        return () => {
            document.body.classList.remove('body-scroll-lock');
        };
    }, [isSuccess]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setSuccess(false);
                navigate('/profile');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navigate]);

    return (
        <AnimatePresence>
            {isSuccess && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'spring', duration: 0.7 }}
                    className="success-quiz-popup__layout"
                >
                    <div ref={popupRef} className="success-quiz-popup__body">
                        <img src={success} className='success-quiz-popup__img' alt="Success" />
                        <p className='success-quiz-popup__title'>Анкета успешно сохранена.</p>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
                            <button onClick={() => navigate('/profile')} className='success-quiz-popup__button'>В профиль</button>
                            {new URLSearchParams(window.location.search).get("fromSearch") === 'true' && (
                                <button
                                    className='success-quiz-popup__button'
                                    onClick={() => navigate('/employee-search')}
                                >
                                    Назад к поиску
                                </button>
                            )}
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default SuccessQuizPopup;
