import { motion } from 'framer-motion';
export const PageTransition = (props) => {
  return (
    <motion.div
      {...props}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: 'spring', duration: .7 }}
      className='page-transition__div'

    >
      {props.children}
    </motion.div>
  )
}