import Select from 'react-select';

export const DefaultSelect = ({ placeholder, options, onChange, selectedOptions, onSelectedChange, value, isMulti, width, required }) => {
    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            display: 'flex',
            alignItems: 'center',
            width: width || '100%',
            fontFamily: 'Circe',
            height: '40px',
            zIndex: '20',
            borderRadius: '10px',
            borderColor: '#DAF0F9',
            textAlign: 'left',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                border: state.isFocused ? '1px solid #B5E2F4' : '1px solid #B5E2F4'
            },
            opacity: state.isDisabled ? .5 : 1,
            backgroundColor: state.isDisabled ? '#fff' : '#fff',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '10px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: state.isSelected ? '#48849C' : '#fff',
            color: state.isSelected ? '#fff' : '#000',
            '&:hover': {
                backgroundColor: state.isSelected ? '#48849C' : '#DAF0F9'
            },
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            fontFamily: 'Circe',
            paddingLeft: '10px'
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Circe',
            width: '100%',
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '16px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontWeight: 'normal',
            color: '#000',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: '16px',
            fontFamily: 'Circe',
            color: '#000',
        }),
        menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
            textAlign: 'left',
            borderColor: '#DAF0F9',
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            zIndex: 9999,
            borderRadius: '10px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontSize: '16px',
            borderColor: '#DAF0F9',
            overflow: 'hidden'
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: '',
        }),
        indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: 'none'
        }),
        dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: '#6BC4E8'
        }),
        ValueContainer: (baseStyles) => ({
            ...baseStyles,
            position: 'absolute !important',
        }),
    };





    const handleChange = (selectedOptions) => {
        onSelectedChange(selectedOptions);
    };


    return (
        <Select
            isMulti={isMulti}
            styles={selectStyles}
            placeholder={placeholder}
            isSearchable={false}
            options={options}
            value={value}
            onChange={handleChange}
            required={required}
        />
    )
}