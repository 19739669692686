import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as Close } from '../../utils/images/close.svg';
import { ReactComponent as Back } from '../../utils/images/back.svg';
import { DefaultSelect } from '../../components/Select/DefaultSelect';
import './MailingModule.css';
import api from '../../utils/Api';

const MailingModule = ({ setOpened, isOpened }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [mailingTemplates, setMailingTemplates] = useState({});
    const [selectedTemplateText, setSelectedTemplateText] = useState('');
    const [selectedRecipient, setSelectedRecipient] = useState('');
    const [mailingText, setMailingText] = useState('');
    const [countdown, setCountdown] = useState(5);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSuccess, setSuccess] = useState(true);
    const popupRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpened(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setOpened]);

    useEffect(() => {
        if (currentStep === 2) {
            setCountdown(5);
            setIsDisabled(true);
            const timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setIsDisabled(false);
                        return 0;
                    }
                    return Math.max(prev - 1, 0);
                });
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [currentStep]);

    useEffect(() => {
        setMailingText(selectedTemplateText?.value);
    }, [selectedTemplateText])

    useEffect(() => {
        const getMailingTemplates = async () => {
            try {
                const token = localStorage.getItem('jwt');
                const res = await api.getMailingTemplates(token);
                setMailingTemplates(res);
            } catch (error) {
                console.error(error);
            }
        };

        getMailingTemplates();

        return () => {
            setCurrentStep(1);
            setMailingTemplates({});
            setSelectedTemplateText('');
            setSelectedRecipient('');
            setCountdown(5);
            setIsDisabled(false);
            setSuccess(true);
        };
    }, []);

    const recipientOptions = [
        { value: 'all_people', label: 'Всем сотрудникам' },
        { value: 'only_inspector', label: 'Только руководителям' },
        { value: 'only_staff', label: 'Только сотрудникам' },
    ];

    const templatesOptions = mailingTemplates?.text
        ? Object.values(mailingTemplates.text).map((template) => ({
            value: template,
            label: `${template.slice(0, 30)}...`,
        }))
        : [];

    const handleNext = () => {
        if (currentStep < 3) setCurrentStep((prev) => prev + 1);
    };

    const handleBack = () => {
        if (currentStep > 1) setCurrentStep((prev) => prev - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (currentStep === 1 && selectedRecipient && selectedTemplateText) {
            setCurrentStep(2);
        } else {
            try {
                const token = localStorage.getItem('jwt');
                const res = api.sendMailing(token, selectedRecipient.value, mailingText);
                setSuccess(true)
            } catch (error) {
                console.log(error);
                setSuccess(false)
            } finally {
                setCurrentStep(3);
                setTimeout(() => {
                    setOpened(false);
                    setCurrentStep(1);
                }, 3000)
            }
        }
    };

    const stepVariants = {
        initial: { x: 300, opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: -300, opacity: 0 },
    };

    const stepContent = [
        {
            id: 1,
            content: (
                <>
                    <div className="mailing-module__select-row">
                        <DefaultSelect
                            options={recipientOptions}
                            placeholder="Выберите группу для рассылки"
                            onSelectedChange={(selectedOption) =>
                                setSelectedRecipient(selectedOption)
                            }
                            value={selectedRecipient}
                            required={true}
                        />
                        <DefaultSelect
                            options={templatesOptions}
                            placeholder="Выберите шаблон"
                            onSelectedChange={(selectedOption) =>
                                setSelectedTemplateText(selectedOption)
                            }
                            value={selectedTemplateText}
                        />
                    </div>
                    <p style={{ width: '100%', textAlign: 'left' }}>Вы можете редактировать шаблон в поле ниже или написать свой текст.</p>
                    <div className="mailing-module__template-edit">
                        <label htmlFor="template-text">Предпросмотр сообщения:</label>
                        <textarea
                            id="template-text"
                            value={mailingText}
                            onChange={(e) => setMailingText(e.target.value)}
                            rows="4"
                            placeholder="Выбранный текст шаблона будет здесь..."
                            required
                            minLength={10}
                        />
                    </div>
                    <button type='submit' className='mailing-module__button'>
                        Далее
                    </button>
                </>
            ),
        },
        {
            id: 2,
            content: (
                <>
                    <div className='mailing-module__text-block'>
                        <p>Сообщение отправится: <span style={{ fontWeight: 'bold' }}>{selectedRecipient.label}</span></p>
                        <p
                            style={{ textAlign: 'left' }}
                        >Учтите, сообщение отправится сразу после нажатия кнопки отправить, для всей выбранной группы. Если сообщение не отправилось, не стоит повторять отправку, для исключения спама. </p>
                    </div>
                    <div className="mailing-module__template-edit">
                        <label htmlFor="template-text">Предпросмотр сообщения:</label>
                        <textarea
                            id="template-text"
                            value={`Добрый день, Имя Отчество. ${mailingText}`}
                            onChange={(e) => setSelectedTemplateText(e.target.value)}
                            rows="4"
                            placeholder="Выбранный текст шаблона будет здесь..."
                            readOnly
                        />
                    </div>
                    <button
                        className="mailing-module__progress-button"
                        type="submit"
                        disabled={isDisabled}
                    >
                        <p>{!isDisabled ? 'Отправить' : `Отправить (${countdown})`}</p>
                        <motion.div
                            layout
                            className="mailing-module__fill-button"
                            initial={{ x: '-100%' }}
                            animate={{ x: '0%' }}
                            transition={{ duration: 5, type: 'linear' }}
                        />
                    </button>
                </>
            ),
        },
        {
            id: 3,
            content: (
                <>
                    <div className='mailing-module__final-block'>
                        <p>{isSuccess ? 'Рассылка успешно сформирована!' : 'Произошла ошибка.'}</p>
                        <p>{isSuccess ? 'Окно автоматически закроется через несколько секунд.' : 'Попробуйте позднее.'}</p>
                    </div>
                </>
            ),
        },
    ];

    return (
        <AnimatePresence>
            {isOpened && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'tween', duration: 0.3 }}
                    className="calibrate-popup__layout"
                >
                    <motion.form className="mailing-module__div" ref={popupRef} onSubmit={handleSubmit}>
                        <div className='mailing-module__title-row'>
                            {currentStep === 2 ? <Back onClick={handleBack} /> : <div style={{ width: '30px', height: '30px' }}></div>}
                            <h2>
                                {currentStep === 1
                                    ? 'Создание рассылки в Telegram Bot'
                                    : currentStep === 2
                                        ? 'Отправка рассылки'
                                        : ''}
                            </h2>
                            <Close onClick={() => setOpened(false)} />
                        </div>
                        <AnimatePresence mode="wait">
                            {stepContent.map(
                                (step) =>
                                    step.id === currentStep && (
                                        <motion.div
                                            key={step.id}
                                            variants={stepVariants}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            transition={{ type: 'tween', duration: 0.3 }}
                                            className="animation__div"
                                        >
                                            {step.content}
                                        </motion.div>
                                    )
                            )}
                        </AnimatePresence>
                    </motion.form>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default MailingModule;
