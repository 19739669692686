import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import QuizSearchModule from "./QuizSearchModule";
import EmployeeSearchModule from "./EmployeeSearchModule";
import arrow_left from "../../utils/images/arrow_left.svg";

function SearchModule() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isQuizSearch, setQuizSearch] = useState(true);
    useEffect(() => {
        const isQuizParam = searchParams.get("isQuizSearch");
        setQuizSearch(isQuizParam === "true" || isQuizParam === null);
    }, [searchParams]);


    const handleToggle = (value) => {
        setQuizSearch(value);
        setSearchParams({ isQuizSearch: value });
    };

    return (
        <div className="employee-search__page">
            <button
                className="search-module__button-div"
                onClick={() => navigate('/hr')}
            >
                <img className="search-module__button" src={arrow_left} alt="" />
                <p>В личный кабинет</p>
            </button>
            <div className="search-module__toggle__div">
                <div
                    onClick={() => handleToggle(true)}
                    className={`search-module__toggle__item ${isQuizSearch ? "search-module__toggle__item_active" : ""}`}
                >
                    По анкетам
                </div>
                <div
                    onClick={() => handleToggle(false)}
                    className={`search-module__toggle__item ${!isQuizSearch ? "search-module__toggle__item_active" : ""}`}
                >
                    По сотрудникам
                </div>
            </div>
            {isQuizSearch ? <QuizSearchModule /> : <EmployeeSearchModule />}
        </div>
    );
}

export default SearchModule;
