import { useEffect, useState } from 'react';
import './ScoreModule.css';
import api from '../../utils/Api';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingComponent from '../../utils/LoadingComponent';
import { convertDateToQuarter, formatDate, getDeadlineDateString } from '../../utils/TimeConvertFunctions';
import useUserStore from '../../utils/stores/useUserStore';

function ScoreModule({ userProfile, isYours }) {
    const [scoresList, setScoreList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const itemsPerPage = 10;
    const navigate = useNavigate();

    const { setUserQuizList, setIsLoading, isLoading, fetchSettings } = useUserStore();

    const getLabel = (type) => (isMobile ? (type === 'res' ? 'Результат' : 'Ценности') : (type === 'res' ? 'Личная результативность' : 'Оценка по компетенциям'));

    const updateIsMobile = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateIsMobile);
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    useEffect(() => {
        if (userProfile?.id) {
            const fetchScoresList = async () => {
                setIsLoading(true);
                try {

                    const token = localStorage.getItem('jwt');
                    const res = await api.fetchScores(token, userProfile.id);
                    setScoreList(res.results);
                    setUserQuizList(res.results);
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchScoresList();
        }
    }, [userProfile]);


    const getGradeSymbol = (score) => {
        const symbols = { 5.0: 'A', 4.0: 'B', 3.0: 'C', 1.5: 'D', 0.0: 'E' };
        return symbols[score] || '?';
    };

    const getRequiredScore = (quiz) => {
        return quiz.answer_on_question_for_calibration
            ?.filter(item => item.category_name === 'Итоговая оценка')
            ?.sort((a, b) => {
                const priority = {
                    "Личная результативность": 1,
                    "Оценка по компетенциям": 2
                };
                return (priority[a.question_name] || Infinity) - (priority[b.question_name] || Infinity);
            })
            ?.map((item, index) => (
                <div key={index} className='score-module__score-item'>
                    {getGradeSymbol(item.answer)}
                </div>
            ));
    };


    const goToQuiz = (quizId) => navigate(`/quiz/${quizId}`);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = scoresList.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = Array.from({ length: Math.ceil(scoresList.length / itemsPerPage) }, (_, i) => i + 1);

    if (isLoading) return null;

    return (
        <>
            <>
                {scoresList?.filter(quiz => quiz.status_doc_quiz_name === 'Завершен').length > 0 && (
                    <div className='score-module__block'>
                        <div className='score-module__header'>
                            <div className='score-module__header__col' style={{ width: '60%' }}>
                                <p style={{ width: '100%', textAlign: 'left', fontSize: '15px' }}>История оценок</p>
                            </div>
                        </div>
                        <div className='score-module__subheader'>
                            <div className='score-module__subheader__col'>
                                <p>Период</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }} id='score-final-empl'>
                                <div className='score-module__subheader__col' id='score-final-result'>
                                    <p>{getLabel('res')}</p>
                                </div>
                                <div className='score-module__subheader__col' id='score-final-result'>
                                    <p>{getLabel('comp')}</p>
                                </div>
                            </div>

                        </div>
                        {currentItems?.filter(quiz => quiz.status_doc_quiz_name === 'Завершен').map((quiz) => (
                            <div className='score-module__item' id={quiz.id} key={quiz.id} onClick={() => goToQuiz(quiz.id)}>
                                <div className='score-module__history-block'>
                                    {convertDateToQuarter(quiz.quarter)}
                                </div>
                                <div className='score-module__final-block'>
                                    {getRequiredScore(quiz)}
                                </div>
                            </div>
                        ))}
                        {pageNumbers.length > 1 && (
                            <div className="pagination">
                                {pageNumbers.map(number => (
                                    <div key={number} className={`page-item ${currentPage === number ? 'page-item_active' : ''}`} onClick={() => setCurrentPage(number)}>
                                        <button className="page-link">{number}</button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </>
        </>
    );
}

export default ScoreModule;
