import React, { useEffect, useState } from 'react';
import './HRModule.css';
import { motion } from 'framer-motion';
import { ReactComponent as Report } from '../../utils/images/excel.svg';
import { ReactComponent as Search } from '../../utils/images/search_rofl.svg';
import { ReactComponent as QuizSend } from '../../utils/images/quiz.svg';
import { ReactComponent as Telegram } from '../../utils/images/telegram.svg';
import { useNavigate } from 'react-router-dom';
import arrow_left from "../../utils/images/arrow_left.svg";
import useUserStore from '../../utils/stores/useUserStore';
import ReportModule from '../ReportModule/ReportModule';
import CreateQuizModule from '../CreateQuizModule/CreateQuizModule';
import MailingModule from '../MailingModule/MailingModule';

function HRModule() {
    const navigate = useNavigate();

    const { isLoading, userProfile } = useUserStore();
    const [isReportOpened, setReportOpened] = useState(false);
    const [isCreateQuizOpened, setCreateQuizOpened] = useState(false);
    const [isMailingOpened, setMailingOpened] = useState(false);
    if (isLoading) return null;


    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('ru-RU', { month: 'long' });
    const dayOfWeek = currentDate.toLocaleString('ru-RU', { weekday: 'long' });

    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, staggerChildren: 0.2 },
        },
    };

    const blockVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    };

    return (
        <>
            <div className='hr-module__page'>
                <button
                    className="search-module__button-div"
                    onClick={() => navigate('/profile')}
                >
                    <img className="search-module__button" src={arrow_left} alt="" />
                    <p>В профиль</p>
                </button>
                <motion.div
                    className="hr-module__div"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >

                    <motion.div className="hr-module__header">
                        <div className="hr-module__header__subheader">
                            <p>Текущий квартал: <span>4 квартал 2024</span></p>
                            <p>Ваш Бизнес-Юнит: <span>{userProfile?.BU_name}</span></p>
                        </div>
                        <motion.div className="hr-module__header__body" variants={blockVariants}>
                            <div className="hr-module__welcome-block">
                                <h2>Добро пожаловать, {userProfile?.first_name}</h2>
                                <p>Это ваш личный кабинет</p>
                            </div>
                            <div className="hr-module__date-block">
                                <div className="hr-module__date-circle">{day}</div>
                                <div className="hr-module__date-text">
                                    <p>{dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)},</p>
                                    <p style={{ fontWeight: 'bold' }}>{month.charAt(0).toUpperCase() + month.slice(1)}</p>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                    <motion.div className="hr-module__main-block" variants={containerVariants}>
                        <motion.div className="hr-module__buttons-row" variants={containerVariants}>
                            <motion.button
                                className="hr-module__s-button"
                                onClick={() => navigate('/employee-search')}
                            >
                                Перейти к поиску <Search />
                            </motion.button>
                            <motion.button
                                className="hr-module__s-button"
                                onClick={() => setCreateQuizOpened(true)}
                            >
                                Рассылка анкет <QuizSend />
                            </motion.button>
                            <motion.button
                                className="hr-module__s-button grey-button"
                                onClick={() => setReportOpened(true)}
                            >
                                Выгрузка отчета <Report />
                            </motion.button>
                            <motion.button
                                className="hr-module__s-button grey-button"
                                onClick={() => setMailingOpened(true)}
                            >
                                Создание рассылки <Telegram />
                            </motion.button>
                        </motion.div>
                        <motion.div className="hr-module__dashboard-block" variants={containerVariants}>
                            <motion.div className="hr-module__left-dash">
                            </motion.div>
                            <motion.div className="hr-module__right-dash">
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
                <ReportModule isOpened={isReportOpened} setOpened={setReportOpened} />
                <CreateQuizModule isOpened={isCreateQuizOpened} setOpened={setCreateQuizOpened} />
                <MailingModule isOpened={isMailingOpened} setOpened={setMailingOpened} />
            </div>
        </>
    );
}

export default HRModule;
