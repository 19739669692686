import React, { useEffect, useRef, useState } from 'react';
import api from '../../utils/Api';
import './EmployeeModule.css';
import { useLocation, useNavigate } from 'react-router-dom';
import arrow_blue from '../../utils/images/arrow_down_blue.svg';
import QuizDetails from './QuizDetails';
import LoadingComponent from '../../utils/LoadingComponent';
import useUserStore from '../../utils/stores/useUserStore';
import { AnimatePresence, motion } from 'framer-motion';
import QuizList from './QuizDetails';

function EmployeeModule({ userProfile, isYours }) {
    const [quizList, setQuizList] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [displayBlock, setDisplayBlock] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const { isLoading, setIsLoading, fetchSettings } = useUserStore();

    useEffect(() => {
        fetchQuizWithInspector(currentPage, selectedFilter);
    }, [currentPage, selectedFilter]);

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedFilter]);

    const FilterBlock = () => {
        const [isFilterOpened, setFilterOpened] = useState(false);
        const filterRef = useRef(null);

        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setFilterOpened(false);
            }
        };

        useEffect(() => {
            if (isFilterOpened) {
                document.addEventListener('mousedown', handleClickOutside);
            } else {
                document.removeEventListener('mousedown', handleClickOutside);
            }

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [isFilterOpened]);

        return (
            <div ref={filterRef}>
                <AnimatePresence>
                    {isFilterOpened ? (
                        <>
                            <div
                                className='employee-module__button'
                                onClick={() => setFilterOpened(false)}>
                                Фильтр
                                <img src={arrow_blue} style={{ transition: '.2s ease-in-out', transform: 'rotate(180deg)' }}></img>
                            </div>
                            <motion.div
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                key='empl-module__menu'
                                className='employee-module__menu'>
                                <div
                                    onClick={() => setSelectedFilter('Создана')}
                                    className={`employee-module__menu__item${selectedFilter === 'Создана' ? '_active' : ''}`}>Не заполнено сотрудником</div>
                                <div
                                    onClick={() => setSelectedFilter('Оценен сотрудником')}
                                    className={`employee-module__menu__item${selectedFilter === 'Оценен сотрудником' ? '_active' : ''}`}>Не заполнено руководителем</div>
                                <div
                                    onClick={() => setSelectedFilter(null)}
                                    className={`employee-module__menu__item${selectedFilter === null ? '_active' : ''}`}>Показать все</div>
                            </motion.div>
                        </>
                    ) : (
                        <div
                            className='employee-module__button'
                            onClick={() => setFilterOpened(true)}>
                            Фильтр
                            <img src={arrow_blue} style={{ transition: '.2s ease-in-out', transform: 'rotate(0deg)' }}></img>
                        </div>
                    )}
                </AnimatePresence>
            </div>
        );
    };

    const Pagination = ({ currentPage, totalPages, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

        return (
            <div className='pagination'>
                {pages.map(page => (
                    <button
                        key={page}
                        onClick={() => onPageChange(page)}
                        className={`page-item ${currentPage === page ? 'page-item_active' : ''}`}>
                        {page}
                    </button>
                ))}
            </div>
        );
    };

    useEffect(() => {
        const fetchQuizInitial = async () => {
            try {
                setIsLoading(true);
                const params = {
                    inspector_id: userProfile.id,
                    status_finishing: 'False',
                };

                const token = localStorage.getItem('jwt');
                const response = await api.getQuizes(token, params);
                const allQuizzes = response.results;
                if (allQuizzes.length > 0) {
                    setDisplayBlock(true);
                } else {
                    setDisplayBlock(false);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchQuizInitial();
    }, [userProfile.id]);

    const fetchQuizWithInspector = async (page, filter) => {
        try {
            const params = {
                inspector_id: userProfile.id,
                status_finishing: 'False',
                page
            };

            if (filter) {
                params.status_doc_quiz__name = filter === 'Оценен сотрудником || Создана' ? 'Оценен сотрудником, Создана' : filter;
            }

            const token = localStorage.getItem('jwt');
            const response = await api.getQuizes(token, params);
            const allQuizzes = response.results;

            setQuizList(allQuizzes);
            setTotalPages(Math.ceil(response.count / 50));
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (isLoading) return null;

    return (
        <>
            <AnimatePresence>
                {displayBlock &&
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: 'tween' }}
                        className='employee-module__block'
                        key='employee-module'
                    >
                        <div className='employee-module__header'>
                            <p>Анкеты сотрудников</p>
                            <FilterBlock />
                        </div>
                        <div className='employee-module__subheader'>
                            <p style={{ textAlign: 'left' }}>ФИО</p>
                            <p id='subheader-employee'>Сотрудник</p>
                            <p id='subheader-manager'>Руководитель</p>
                        </div>
                        <QuizList quizList={quizList} isLoading={isLoading} />
                        {totalPages > 1 && <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />}
                    </motion.div>
                }
            </AnimatePresence>
        </>
    );
}

export default EmployeeModule;
