import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../../utils/Api";
import "./SearchModule.css";
import search from "../../utils/images/search.svg";
import useUserStore from "../../utils/stores/useUserStore";
import { motion } from "framer-motion";
import { EmployeeSearchItem } from "./SearchListItem";
import LoadingComponent from "../../utils/LoadingComponent";
import MassCalibratePopup from "./MassCalibratePopup";

function EmployeeSearchModule() {
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isCalibratePopupOpened, setCalibratePopupOpened] = useState(false);
    const { isLoading, setIsLoading } = useUserStore();
    const [searchParams, setSearchParams] = useSearchParams();
    const inputValue = searchParams.get("query") || "";
    
    const debounceRef = useRef();

    const updateSearchParams = (query) => {
        const params = new URLSearchParams(searchParams);
        if (query) {
            params.set("query", query);
        } else {
            params.delete("query");
        }
        setSearchParams(params);
    };

    const searchQuizzes = async (query) => {
        const token = localStorage.getItem("jwt");
        try {
            setIsLoading(true);
            const response = await api.searchByAnyField(token, query, page);
            setResults((prevResults) => [...prevResults, ...response.results]);
            setTotalPages(Math.ceil(response.count / 50));
        } catch (error) {
            console.error("Error fetching quizzes:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        document.title = "Pridex Plus | Поиск сотрудника";
    }, []);

    const lastItem = useRef();

    const actionInSight = (entries) => {
        if (entries[0].isIntersecting && page < totalPages) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const observerLoader = useRef();

    useEffect(() => {
        if (observerLoader.current) {
            observerLoader.current.disconnect();
        }

        observerLoader.current = new IntersectionObserver(actionInSight);

        if (lastItem.current) {
            observerLoader.current.observe(lastItem.current);
        }
    }, [lastItem, results]);

    useEffect(() => {
        if (page > 1) {
            searchQuizzes(inputValue);
        }
    }, [page, inputValue]);

    useEffect(() => {
        if (inputValue) {
            setResults([]);
            setPage(1);
            clearTimeout(debounceRef.current);
            debounceRef.current = setTimeout(() => {
                searchQuizzes(inputValue);
            }, 500);
        } else {
            setResults([]);
        }
    }, [inputValue]);

    return (
        <>
            <div className="employee-search__div">
                <form
                    className="employee-search__row"
                    id="empl-first-row"
                    onSubmit={(e) => {
                        e.preventDefault();
                        updateSearchParams(e.target.elements.query.value);
                    }}
                >
                    <input
                        className="employee-search__input"
                        type="text"
                        name="query"
                        placeholder="Введите ФИО..."
                        value={inputValue}
                        onChange={(e) => updateSearchParams(e.target.value)}
                    />
                    <button className="employee-search__button" type="submit">
                        <img src={search} alt="Search Icon" />
                    </button>
                </form>
            </div>

            <div className="employee-search__main-div">
                <div className="employee-search__header__div">
                    <p>ФИО</p>
                    <p>Отдел</p>
                    <p>Должность</p>
                </div>

                {isLoading && page === 1 ? (
                    <LoadingComponent />
                ) : results.length > 0 ? (
                    <motion.div
                        className="employee-search__list"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        {results.map((result, index) =>
                            index + 1 === results.length ? (
                                <EmployeeSearchItem item={result} key={result.id} ref={lastItem} />
                            ) : (
                                <EmployeeSearchItem item={result} key={result.id} />
                            )
                        )}
                    </motion.div>
                ) : (
                    !isLoading && (
                        <p
                            style={{
                                height: "80px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            Поиск не дал результатов. Выберите новые значения фильтров и/или нажмите кнопку поиска.
                        </p>
                    )
                )}
                {isLoading && page > 1 && <LoadingComponent />}
            </div>
            <MassCalibratePopup isOpened={isCalibratePopupOpened} setIsOpened={setCalibratePopupOpened} />
        </>
    );
}

export default EmployeeSearchModule;
